import { Grid, Typography } from "@material-ui/core"
import React from "react"
import ReactMaterialComponentBase from "../../Shared/ReactMaterialComponentBase"
import { ListRecipe as ReactListRecipe } from "../React/ListRecipe"

export default class SearchRecipesByBook extends ReactMaterialComponentBase {
  static get observedAttributes() {
    return ["target"]
  }

  constructor() {
    super()

    /**
     * @type {Array<import("../Types").Recipe>}
     */
    this.recipes = []

    this.searchedRecipes = false

    this.jsxRootComponent = () => {
      const noRecipes = this.searchedRecipes && !this.recipes.length

      return (
        <Grid container spacing={2} style={{ margin: 0, width: "100%" }}>
          {noRecipes ? (
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography variant="h4">No Recipes</Typography>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <ReactListRecipe
                recipes={this.recipes}
                recipeSelected={(recipe) => this.recipeSelected(recipe)}
                updateRecipe={(recipe) => this.updateRecipe(recipe)}
              />
            </Grid>
          )}
        </Grid>
      )
    }
  }

  async attributeChangedCallback(name, oldValue, newValue) {
    if (name !== "target") {
      return
    }

    await this.getRecipes(newValue)
    this.render()
  }

  /**
   * @param {import("../Types").Recipe} recipe
   */
  recipeSelected(recipe) {
    this.dispatchEvent(new CustomEvent("recipe-selected", { bubbles: true, composed: true, detail: { recipe } }))
  }

  /**
   * @param {string} url
   */
  async getRecipes(url) {
    try {
      this.searchedRecipes = false

      const token = await window.authorise()

      const getRecipesResponse = await fetch(`${url}`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
      })

      const recipes = /** @type {Array<import("../Types").Recipe>} */ (await getRecipesResponse.json())

      this.recipes = recipes
    } catch (error) {
      this.onError && this.onError(error)
    } finally {
      this.searchedRecipes = true
    }
  }

  async updateRecipe(recipe) {
    const target = this.getAttribute("target")

    try {
      const token = await window.authorise()

      const getRecipesResponse = await fetch(`${target}/${recipe.id}`, {
        method: "PUT",
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        body: JSON.stringify(recipe),
      })

      const recipes = /** @type {Array<import("../Types").Recipe>} */ (await getRecipesResponse.json())

      this.recipes = recipes
    } catch (error) {
      this.onError && this.onError(error)
    }
  }
}

customElements.define("search-recipe-book", SearchRecipesByBook)
